<template>
<div v-if="!items">Loading Please wait...
  </div>
<div v-else>
  <b-card>
    <div class="d-flex align-items-center justify-content-end mb-1">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="addEntry"
      >
        <feather-icon
          icon="PlusSquareIcon"
          class="mr-50"
        />
        <span class="align-middle">Neuer Eintrag</span>
      </b-button>
    </div>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :items="items"
      primary-key="ProtID"
      :fields="fields"
      @row-selected="showEintrag"
    >
      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col
              md="4"
            >
              <b-form-group
                label="Datum"
                label-for="Datum"
              >
                <flat-pickr
                  v-model="row.item.Datum"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-form-group
                label="Dauer"
                label-for="Dauer"
              >
                <b-time
                  v-model="row.item.Dauer"
                  :disabled="row.item.HonorarID!==null"
                  :hide-header="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-form-group
                label="Task"
                label-for="Task"
              >
                <v-select
                  v-model="row.item.TaskID"
                  :options="tasks"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                label="Titel"
                label-for="Titel"
              >
                <b-form-input
                  v-model="row.item.Titel"
                  trim
                  placeholder="Aktivität..."
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                label="Kostensatz"
                label-for="Kostensatz"
              >
                <v-select
                  v-model="row.item.Rolle"
                  :options="kostSatz"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="row.item.HonorarID!==null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-form-group
                label="Verrechenbar"
                label-for="verrechenbar"
              >
                <b-form-checkbox
                  v-model="row.item.Verrechenbar"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  :disabled="row.item.HonorarID!==null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <b-form-group
                label="Beschreibung"
                label-for="Beschreibung"
              >
                <b-form-textarea
                  v-model="row.item.Beschrieb"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichern(row.item)"
          >
            Speichern
          </b-button>
        </b-card>
      </template>

    </b-table>

  </b-card>
</div>

</template>

<script>
import {
  BButton, BCard, BTable, BRow, BCol, BFormGroup, BFormCheckbox, BTime, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import Ripple from 'vue-ripple-directive'
import db from '@/service/protokoll'
import dbt from '@/service/dossier'
import dbm from '@/service/mandant'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    BFormCheckbox,
    BTime,
    vSelect,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  setup() {
    const kostSatz = []
    const tasks = []
    return {
      kostSatz,
      tasks,
    }
  },
  data() {
    return {
      totalRows: 1000,
      fields: [
        // { key: 'show_details', label: 'Details' },
        { key: 'Erstellt', label: 'Datum' },
        { key: 'Titel', label: 'Titel' },
      ],
      /* eslint-disable global-require */
      items: null,
      currRow: null,
      selected: [],
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async created() {
    const r = await db.getProtokoll(this.$root.currDosID)
    if (r.data.length > 0) {
      for (let i = 0; i < r.data.length; i += 1) r.data[i]._showDetails = false // eslint-disable-line
      this.items = r.data
      this.totalRows = this.items.length
    }
    const m = await dbm.getMandant(this.$g.user.MandantID)
    if (m.data.length === 1) {
      this.kostSatz = [
        { label: m.data[0].KostenBez1, value: 1 },
        { label: m.data[0].KostenBez2, value: 2 },
        { label: m.data[0].KostenBez3, value: 3 },
      ]
    }
  },
  async mounted() {
    const t = await dbt.getDossierWorkflowDropDown(this.$root.currDosID)
    this.tasks = t.data !== null ? t.data : []
  },
  methods: {
    showEintrag(items) {
      if (items.length > 0) this.currRow = items[0]
      this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    async speichern(item) {
      if (item.ProtID > 0) {
        await db.putProtokoll(item)
      } else {
        const r = await db.newProtokoll(item)
        this.items[0].ProtID = r.data[0]
      }
      this.$root.showToast('Änderungen gespeichert', 'Protokolleintrag wurde erfolgreich gespeichert!')
    },
    addEntry() {
      for (let i = 0; i < this.items.length; i += 1) {
        this.items._showDetails = false // eslint-disable-line
        this.$refs.selectableTable.unselectRow(i)
      }
      const np = JSON.parse(JSON.stringify(this.items)).slice(0, 1)
      np[0].ProtID = -(this.items.length + 1)
      np[0].Datum = new Date()
      np[0].Titel = ''
      np[0].Dauer = '0:30'
      np[0].Beschrieb = ''
      np[0]._showDetails = true // eslint-disable-line
      this.items.unshift(np[0])
      this.totalRows = this.items.length
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
